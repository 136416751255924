export default {
  en: {
    title: 'Terms of Use',
    describe: [
      `"App" as used here in refers to all products and services owned and operated by Shenzhen
      XCINFO Technology Co., Ltd..`
    ],
    contents: [
      {
        subtitle: 'Terms',
        content: [
          `By using the "App", you are agreeing to be bound by these terms of use, all applicable
          laws and regulations, and agree that you are responsible for compliance with any
          applicable local laws. If you do not agree with any of these terms, you are prohibited
          from using the App. The materials contained in this App are protected by applicable
          copyright and trademark law.`
        ]
      },
      {
        subtitle: 'Use License',
        content_li_befor: [
          `Permission is granted to temporarily download one copy of the materials (information or
            software) for personal, non-commercial transitory viewing only. This is the grant of a
            license, not a transfer of title, and under this license you may not:`
        ],
        content_li: [
          `modify or copy the materials;`,
          `use the materials for any commercial purpose, or for any public display (commercial or
          non-commercial);`,
          `attempt to decompile or reverse engineer any software contained within the app;`,
          `Carry out other purposes that are disclosed to you and to which you consent.`,
          `remove any copyright or other proprietary notations from the materials;`,
          `transfer the materials to another person or "mirror" the materials on any other server.`
        ],
        content: [
          `This license shall automatically terminate if you violate any of these restrictions and
          may be terminated by Shenzhen XCINFO Technology Co., Ltd. at any time. Upon terminating
          your viewing of these materials or upon the termination of this license, you must destroy
          any downloaded materials in your possession whether in electronic or printed format.`
        ]
      },
      {
        subtitle: 'Disclaimer',
        content_li: [
          `The materials within the "App" are provided on an 'as is' basis. The "App" makes no
          warranties, expressed or implied, and hereby disclaims and negates all other warranties
          including, without limitation, implied warranties or conditions of merchantability,
          fitness for a particular purpose, or non-infringement of intellectual property or other
          violation of rights.`,
          `Further, The "App" does not warrant or make any representations concerning the accuracy,
          likely results, or reliability of the use of the materials within the app.`,
        ]
      },
      {
        subtitle: 'Limitations',
        content: [
          `In no event shall the "App" or its suppliers be liable for any damages (including,
          without limitation, damages for loss of data or profit, or due to business interruption)
          arising out of the use or inability to use the App and the materials within the "App",
          even if the "App" authorized representative has been notified orally or in writing of
          the possibility of such damage. Because some jurisdictions do not allow limitations on
          implied warranties, or limitations of liability for consequential or incidental damages,
          these limitations may not apply to you.`
        ]
      },
      {
        subtitle: 'Accuracy of materials',
        content: [
          `The materials appearing within the "App" could include technical, typographical, or
          photographic errors. The "App" does not warrant that any of the materials on its website
          are accurate, complete or current. The "App" may make changes to the materials contained
          on its website at any time without notice. However the "App" does not make any commitment
          to update the materials.`
        ]
      },
      {
        subtitle: 'Modifications',
        content: [
          `Shenzhen XCINFO Technology Co., Ltd. may revise these terms of use at any time without
          notice. By using the App you are agreeing to be bound by the then current version of
          these terms of use.`
        ]
      },
      {
        subtitle: 'Access',
        content: [
          `You must provide at your own expense the equipment, Internet connections or mobile
          devices and/or service plans to access and use this app. Shenzhen XCINFO Technology Co.,
          Ltd. does not guarantee that the "App" is available in all geographic locations. You
          acknowledge that when You use the "App", your wireless carrier may charge you fees for
          data, messaging, and/or other wireless access. Check with your carrier to see if there
          are any such fees that apply to you. You are solely responsible for any costs You incur
          to access this application from your mobile device and/or PC device. Your right to use
          the "App" is also predicated on your compliance with any applicable terms of agreements
          you have with third parties when using the services.`
        ]
      },
      {
        subtitle: 'Governing Law and Disputes Resolution',
        content: [
          `Except to the extent that: (i) any applicable additional terms incorporated into these
          Terms provide differently, or (ii) the applicable laws and regulations of your
          jurisdiction mandate otherwise, the Agreement and any dispute or claim arising out of or
          in connection with the Agreement will be governed by the laws of the People’s Republican
          of China.`,
          `Any dispute, controversy or claim (whether in contract, tort or otherwise) arising out
          of, relating to, or in connection with the Agreement, including their existence, validity,
          interpretation, performance, breach or termination, will be referred to and finally
          resolved by arbitration administered by the China International Economic and Trade
          Arbitration Commission (CIETAC), and the place of arbitration shall be in Beijing. Within
          the valid period for the arbitration, such arbitration tribunal shall subject to the
          arbitration rules of the CIETAC in effect at the time of the arbitration. The award of
          the arbitration tribunal shall be final and binding upon the two parties.`
        ]
      },
      {
        subtitle: 'Contacting Us',
        content: [
          `If there are any questions regarding this privacy policy you may contact us using the
          information below:`,
          `hustapp@foxmail.com`
        ]
      }
    ]
  }
}
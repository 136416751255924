export default {
  en: {
    title: 'Privacy Policy',
    describe: [
      `As used in this Privacy Policy, “XCINFO”, “We”, “Us” or “Our”, refers to Shenzhen XCINFO
      Technology Co., Ltd.. Please read this Privacy Policy in order to learn more about the data
      we collect, what do we use it for and how we protect your privacy.`
    ],
    contents: [
      {
        subtitle: 'What information do we collect?',
        content: [
          `We collect certain information from your mobile device, including but not limited to the
          following:`,
          `Device and Usage Information that may include(i) information specific to your mobile
          device (e.g., make, model, operating system, advertising identifier and similar
          information); (ii) information about your use of features, functions, or notifications
          on the device; and (iii) signal strength relating to WiFi or Bluetooth functionality,
          temperature, battery level, and similar technical data.`
        ]
      },
      {
        subtitle: 'How we use the information we collect:',
        content_li: [
          `Operate and improve our services.`,
          `Provide user and other support, including to our third-party business partners.`,
          `Provide you with information when you enter participating local venues.`,
          `Carry out other purposes that are disclosed to you and to which you consent.`,
          `To personalize your experience (your information helps us to better respond to your
          individual needs).`
        ],
        content: [
          `We will retain your information for as long as the application is installed on your
          mobile device or as needed to provide you service. If you remove the application, we will
          remove your information and use copies of your information as necessary to comply with
          our legal obligations, resolve disputes, and enforce our agreements.`
        ]
      },
      {
        subtitle: 'Do we share any information to outside parties?',
        content_li: [
          `Operate and improve their services.`,
          `Provide you with advertisements and information for products and services in this app or
          on other apps or websites based on variables such as your location, and assess your level
          of engagement with them.`,
          `Carry out other purposes that are disclosed to you and to which you consent.`,
        ],
        content_li_befor: [
          `We share the information we collect with third-party business partners who commit to
          comply with applicable laws concerning their usage of the information. Our third-party
          business partners may share the information with their own third party business partners
          and use the information either for themselves or on behalf of their own business partners
          to:`
        ]
      },
      {
        subtitle: 'Online Privacy Policy Only',
        content: [
          `This online privacy policy applies only to information collected through our website and
          not to information collected offline.`
        ]
      },
      {
        subtitle: 'Security',
        content: [
          `We use a variety of security technologies and procedures for the purpose of preventing
          loss, misuse, unauthorized access or disclosure of the Information.`,
          `For example, in some of our Services, we use encryption technology to protect certain
          sensitive Information provided by you to us. Please note that no data security measures
          can guarantee 100% security at all times. Our systems and the communications networks
          through which you access our services may be subject to security breaches and failures
          which are due to circumstances beyond our reasonable control.`
        ]
      },
      {
        subtitle: 'Advertising',
        content: [
          `We may collect and use information about you, such as your device identifier, MAC
          address, IMEI, geo-location information and IP address for the purpose of the tracking of
          the advertising. This information is used together to create a unique user number to help
          us to better count and track advertising based on language, geographic location and other
          relevant details. This information is NOT SOLD to outside parties.`
        ]
      },
      {
        subtitle: 'User Logs',
        content: [
          `When using our Services, including engaging with, watching or producing any content in
          relation to our Services, certain user log information in our application will be
          automatically collected, including (i) details on when and how often our Services are
          being used, (ii) devices statistics, critical operation paths, errors, crashes, language
          and time zone and so on. These user logs are used to improve our Services and are NOT
          USED to identify and track our users individually.`
        ]
      },
      {
        subtitle: 'Your Consent',
        content: [
          `By using our site, you consent to our web site privacy policy.`
        ]
      },
      {
        subtitle: 'Children Policy',
        content: [
          `Our services are not intended for—and we don’t direct them to—anyone under 13. And that’s
          why we do not knowingly collect personal information from anyone under 13.`
        ]
      },
      {
        subtitle: 'Revision to the privacy policy',
        content: [
          `We may change this privacy policy from time to time. But when we do, we’ll let you know
          one way or another. Sometimes, we’ll let you know by revising the date at the top of the
          privacy policy that’s available on our website and mobile application. Other times, we may
          provide you with additional notice (such as adding a statement to our websites’ homepages
          or providing you with an in-app notification).`
        ]
      },
      {
        subtitle: 'Contacting Us',
        content: [
          `If there are any questions regarding this privacy policy you may contact us using the
          information below:`,
          `hustapp@foxmail.com`
        ]
      }
    ]
  }
}
export default {
  en: {
    title: '「Water Tracker」 v4.0 Help',
    describe: [
      'Welcome to Water Tracker!',
      `This is the easiest way to track your water balance and help you develop a healthy habit of
      drinking water on time.`,
      'Use Water Tracker to keep you hydrated!'
    ],
    structureTite: 'Table of contents',
    structure: [
      '1.Reminders',
      '2.Creat a Record',
      '3.Editing Records',
      '4.Drink Types',
      '5.Delete Records',
      '6.Redact',
      '7.Customized Drinking Water Goals',
      '8.Apple Health',
      '9.iCould Sync',
      '10.Sounds',
      '11.Notification Texts',
      '12.Upgrade & Restore',
      '13.Widget',
      '14.Others'
    ],
    contents: [
      {
        headline: '1.Reminders',
        subtitle: '1.1 Notification Privileges',
        content: [
          `WaterTracker requires system notification privileges to push water reminders to you.`,
          `How to open: Settings > Notifications > Allow Reminders > Go to System Settings >
        Notifications > Allow Notifications.`
        ],
        imageNum: 0
      },
      {
        subtitle: '1.2 Timing Mode',
        content: [
          `You can select the time you want to remind the reminder.`,
          `Add time: Click "Add Reminders" at the bottom of the page, select the time you want to
          remind, then click "Done".`,
          `Delete time point: left click on the time point you want to delete, the delete button
          will appear on the right side, click on the delete button to quickly delete, click on
          other areas to hide the button.`
        ],
        imageNum: 1
      },
      {
        subtitle: '1.3 Interbal Mode',
        content: [
          `The interval reminder can be reminded at the interval you set during the non-rest
        time you set. Noon break mode: when the noon break mode is enabled, the noon break will be
        treated as a break time and no reminder will be sent during the noon break.`,
        `Custom interval: Click "Custom", select the time interval you want to be reminded, then
        click "Done".`
        ],
        imageNum: 2
      },
      {
        subtitle: '1.4 Smart Notification',
        content: [
          `With Smart Reminder mode on, if you log a drink in the 10 minutes before you
        originally expected the next reminder, that drink will be cancelled, thus reducing pointless
        reminders.`,
        `However, if this drink is recorded through the Apple Watch, the smart alert will not work,
        which is a bug that we're working on a solution for.`
        ]
      },
      {
        subtitle: '1.5 Weekend Mode',
        content: [
          `If you are in weekend mode, you will not receive a drink reminder on Saturday or Sunday
          before 12:00 noon. However, if you wake up and record a drink before 12:00 noon on
          Saturday or Sunday, WaterTracker will resume the normal water reminders for that day.`
        ]
      },
      {
        headline: '2.Create a Record',
        subtitle: '2.1 Creat',
        content: [
          `Click on the "Drink" button at the bottom of the home page to create today's drinking
          history.`,
        `Click the "Add" button at the bottom of the history page to create a history of the current
        day's water consumption.`
        ],
        imageNum: 3
      },
      {
        subtitle: '2.2 Choose Drink Type',
        content: [
          `You can click on the cups you have already set up at the top to make a quick note, or
          click on the drink icons at the bottom to select the drink you want to record.`
        ]
      },
      {
        subtitle: '2.3 Input Value',
        content: [
          `Once you have selected a drink, a numeric keypad will pop up and you will need to enter
          the amount of water you have consumed this time through the numeric keypad.`,
          `You can estimate the amount of water you have consumed by using a graduated cup/bottle or
          by looking at the net content on the packaging of the drink.`
        ],
        imageNum: 4
      },
      {
        subtitle: '2.4 Edit Time',
        content: [
          `Click on the "Time" button at the top header to change the record time for this drinking
          record.`
        ],
        imageNum: 5
      },
      {
        subtitle: '2.5 Save',
        content: [
          `Click the tick button on the numeric keypad at the bottom to save this edit and exit the
          Drinking Water page.`
        ]
      },
      {
        headline: '3.Editing Records',
        content: [
          `By clicking on any of the drinking records in the "WaterTracker" section, you can edit
          them again directly.`,
          `Click on the "Cancel" button in the upper left corner to exit this editing.`,
          `Click on the "Delete" button in the upper right corner to delete the log.`,
          `Click on the "Time" button at the top header to change the record time for this drink.`,
          `Click on the tick button at the bottom of the numeric keypad to save this edit and exit
          the drinking page.`
        ],
        imageNum: 6
      },
      {
        headline: '4.Drink Types',
        subtitle: '4.1 Sort',
        content: [
          `On the "Drink Types" page of the settings page, you can manage your beverages.`,
          `Press and hold the move button to the right of the drink to enter the sorting mode, drag
          and drop the drink to insert it to the desired position and release your finger.`,
          `Click the "minus" button on the left side of your drink to hide the drinks you won't use
          and they will no longer appear on the drink history page.`,
          `Click on the "plus" button to the left of your drinks to remove the drinks that you have
          hidden and they will appear again on the drink log page.`
        ],
        imageNum: 7
      },
      {
        subtitle: '4.2 Water Coefficient',
        content: [
          `Each drink has a different water content, so the amount of water ingested varies. When
          you add a new drink record, we will calculate the water content of that drink before
          adding it to your total intake for the day. You can find the calculation rules in the
          "Water Coefficient" section of the settings page.`
        ]
      },
      {
        subtitle: '4.3 Creat custom drinks',
        content: [
          `You can create a custom drink on the "Drink Types" page of the settings page. By clicking
          "Create custom drinks" at the bottom of the screen, you can customize your new drink in
          many ways.`,
          `Alcoholic or not: whether your new drink will contain alcohol or not.`,
          `Drinking Factor: How many ml of water per 100 ml of new drink (if you can't calculate a
          detailed value, you can refer to the values of other beverages on the Water Coefficient
          page to set an approximate value).`,
          `Caffeine Factor: whether the new drink contains caffeine or not, and how many mg of caffeine
          per 100 ml of new drink.`,
          `You can refer to the values of other alcoholic beverages provided on the Water Coefficient
          page and choose an appropriate multiplier according to the concentration of alcohol.  `
        ],
        imageNum: 8
      },
      {
        headline: '5.Delete Records',
        subtitle: '5.1 Delete on the Drinking Water page',
        content: [
          `Click on the "Delete" button in the upper right corner to delete this drinking record.`
        ]
      },
      {
        subtitle: '5.2 Delete on another page',
        content: [
          `Left click on the record you want to delete and the Delete button will appear on the
          right.`
        ],
        imageNum: 9
      },
      {
        headline: '6.Redact',
        content: [
          `Don't worry if you forgot to record the water you drank yesterday, we provide a history
          function.`,
          `Find this day in the history page and click the "Add" button to the right of the
          "History" title. Click on the time button at the top of the screen to change the time of
          this record.`
        ],
        imageNum: 10
      },
      {
        headline: '7.Customized Drinking Water Goals',
        content: [
          `The amount of water you need is different for each person, so WaterTracker will calculate
          the amount of water you need to drink per day by gender, weight, and physical activity.`,
          `On the "Profile" page, you can modify your personal data. Based on the new data you
          provide, we will calculate a new daily recommended amount of water to drink, and you can
          modify your drinking goals based on the reference values we provide, or adjust them to
          suit your personal needs.`,
          `WaterTracker is not a medical application and the daily recommended amount of water to
          drink is for informational purposes only. Please consult your physician if you wish to
          pursue a medical purpose or obtain more specialized information about your hydration
          needs.`
        ],
        imageNum: 11
      },
      {
        headline: '8.Apple Health',
        content: [
          `Turning on access to Apple Health allows you to transfer your water history to Apple
          Health.`,
          `To turn it on and off: Go to Apple Health > Sources > WaterTracker.`
        ]
      },
      {
        headline: '9.iCloud Sync',
        content: [
          `Turning on iCloud Sync keeps your drinking history in sync across multiple devices
          signed in to the same Apple ID, which requires a network connection (if you can't use the
          network, syncing will happen the next time you successfully connect to the network).`,
          `Your Apple ID is the only account used by WaterTracker, and all features and data will
          follow your Apple ID without you having to log in or out of the app.`
        ]
      },
      {
        headline: '10.Sounds',
        subtitle: '10.1 Select ringtone',
        content: [
          `We offer several interesting ringtones. Go to the settings page via the tab bar at the
          bottom of the screen, tap on "Notification Sounds", turn on the sound switch on your
          device and adjust the sound level of your music to try out and pick your favorite
          ringtone.`
        ],
        imageNum: 12
      },
      {
        subtitle: '10.2 No sound',
        content: [
          `Your phone device may have silent mode on. The on/off, volume and vibrations of the
          ringtone are controlled by your device, and the ringtone will not play properly if your
          phone is in silent mode. Please make sure that the physical switch on the left side of
          your phone that controls ringtones is turned on, and that "Vibrate" and "Ringtone" are
          turned on in the "Sound & Touch" of your system settings.`
        ],
        imageNum: 13
      },
      {
        headline: '11.Notification Texts',
        subtitle: '11.1 System Mode',
        content: [
          `In system mode, WaterTracker will automatically push out the appropriate notification
          texts according to the time of the day.`
        ]
      },
      {
        subtitle: '11.2 Custom Mode',
        content: [
          `In Custom Mode, you can completely customize a set of dedicated notification texts.`,
          `For example, you can set a time period from 7am to 9am and add some good morning
          greetings; you can also set a time period from 9pm to 11pm and add some good night
          greetings.`,
          `You can also set a time slot from 9pm to 11pm and add some notification texts for good
          night greetings.`
        ],
        imageNum: 14
      },
      {
        headline: '12.Upgrade & Restore',
        content: [
          `If you encounter an ad that persists after you have paid, or if an ad appears after
          reinstalling WaterTracker, please click the "Restore" button on the Settings page to
          synchronize your payment information again.`,
          `If you click the "Upgrade" button on the payment page multiple times, rest assured that
          only one purchase can be made under the same Apple ID, and no multiple charges will be
          incurred.`
        ]
      },
      {
        headline: '13.Widget',
        subtitle: '13.1 Cups',
        content: [
          `You can access the "Settings" page from the tab bar at the bottom of the screen and click
          on "Cup" to set their name, drink and volume freely.`,
          `These cups will appear in.`,
          `Home of WaterTracker on Apple Watch`,
          `WaterTracker Page for Drinking Time on iPhone`,
          `Widgets for iPhone and iPad`,
          `3D Touch for iPhone`,
          `Notifications for iPhone and Apple Watch (appears when expanded)`
        ],
        imageNum: 15
      },
      {
        subtitle: '13.2 3D Touch',
        content: [
          `You can use the WaterTracker shortcut via 3D Touch on your device's home screen: find the
          WaterTracker icon on your home screen, press and hold the icon to bring up a list of
          actions and release it.`
        ],
        imageNum: 16
      },
      {
        subtitle: '13.3 Widget',
        content: [
          `You can add WaterTracker to the widget to quickly add a drink record without having to
          open WaterTracker.`,
          `How to add: Slide the system to the leftmost interface, click the "Edit" button at the
          bottom, find WaterTracker, click the "Add" button on the left, and then click "Done" at
          the top right corner.`
        ],
        imageNum: 17
      },
      {
        subtitle: '13.4 Notifications',
        content: [
          `When WaterTracker pushes a drink reminder to you, you can expand the action window to
          take a quick note: When you receive a message push, pull down the message push to expand
          the action window.`
        ],
        imageNum: 18
      },
      {
        headline: '14. Others',
        content: [
          `If you have any other questions or feedback, please feel free to contact us via Sina
          Weibo/email/Facebook in "Help & Feedback" and we will reply to you as soon as possible.`
        ]
      },
    ]
  },


  cn: {
    title: '「喝水时间」 v4.0 使用帮助',
    describe: [
      '欢迎使用「喝水时间」。',
      `这是一款用最简单的方法跟踪您的水平衡的工具，帮您养成按时喝水的健康习惯。`,
      `它可以：计算您每日所需喝水量记录喝水量、定时提醒喝水、分析历史喝水数据……`,
      '使用「喝水时间」，保持水分充足！'
    ],
    structureTite: '目录',
    structure: [
      '1.喝水提醒',
      '2.创建记录',
      '3.编辑记录',
      '4.饮品种类',
      '5.删除记录',
      '6.遗忘补录',
      '7.定制喝水目标',
      '8.Apple 「健康」',
      '9.iCould 同步',
      '10.铃声',
      '11.推送语',
      '12.购买与恢复',
      '13.自定义组件',
      '14.其他问题'
    ],
    contents: [
      {
        headline: '1.喝水提醒',
        subtitle: '1.1 通知权限',
        content: [
          `「喝水时间」需要获取系统通知权限，才能向您推送喝水提醒。`,
          `打开方式：设置页面>通知>允许提醒>跳转至系统设置>通知>允许通知`
        ],
        imageNum: 0
      },
      {
        subtitle: '1.2 定时提醒模式',
        content: [
          `定时提醒可以选择您想要提醒的时间点进行提醒。`,
          `添加时间点：点击页面底部的“添加新的时间点”，选择需要提醒的时间点后，点击“完成”。`,
          `删除时间点：左划需要删除的时间点，右侧将出现删除按钮，点击删除按钮即可快速删除，点击其它区域可以
          隐藏该按钮。`
        ],
        imageNum: 1
      },
      {
        subtitle: '1.3 间隔提醒模式',
        content: [
          `间隔提醒可以在您设定的非休息时间内，按照您设定的时间间隔进行提醒。`,
          `午休模式：开启午休模式后，午休时间将作为休息时间，午休期间不会进行提醒。`,
          `自定义间隔时长：点击“自定义”，选择需要提醒的时间间隔后，点击“完成”。`
        ],
        imageNum: 2
      },
      {
        subtitle: '1.4 智能提醒',
        content: [
          `开启智能提醒模式后，在原本预计下次提醒前的10分钟内，您记录了一次喝水，则这一次的喝水提醒会取消，
          从而减少无意义的提醒。`,
          `但如果这一次喝水记录是通过 Apple Watch 进行记录的，则智能提醒会不生效，这是一个Bug，我们正在想
          办法解决。`
        ]
      },
      {
        subtitle: '1.5 周末模式',
        content: [
          `开启周末模式后，在周六、周日中午的12点前，「喝水时间」将不会为您推送喝水提醒。但如果在周六、周日
          中午的12点前，您已经起床并记录了一次喝水记录后，「喝水时间」将会恢复当天正常的喝水提醒。`
        ]
      },
      {
        headline: '2.创建记录',
        subtitle: '2.1 创建',
        content: [
          `点击首页底部的“喝水”按钮，可以创建今天的喝水记录。`,
          `点击历史页面底部的“添加”按钮，可以创建历史当天的喝水记录。`
        ],
        imageNum: 3
      },
      {
        subtitle: '2.2 选择饮品',
        content: [
          `您可以点击上方已经设置好的杯子来快速进行记录，也可以点击下方各式各样的饮品图标来选择本次需要记录
          的饮品，饮品种类会不定时更新（您可以通过“帮助&反馈”联系我们，告诉我们您还想要添加哪些饮品种类）。`
        ]
      },
      {
        subtitle: '2.3 输入数值',
        content: [
          `选择饮品后，将会弹出数字键盘，您需要通过数字键盘输入本次摄入的水量。`,
          `您可以通过使用标有刻度值的杯子/瓶子，或是观察饮品外包装上标注的净含量来估算您本次摄入的水量数值。`
        ],
        imageNum: 4
      },
      {
        subtitle: '2.4 修改时间',
        content: [
          `点击顶部标题位置的“时间”按钮可以修改这条喝水记录的记录时间。`
        ],
        imageNum: 5
      },
      {
        subtitle: '2.5 保存',
        content: [
          `点击底部数字键盘的“√”按钮可以保存本次编辑，并退出喝水页面。`
        ]
      },
      {
        headline: '3.编辑记录',
        content: [
          `点击「喝水时间」内的任意一条喝水记录，您都可以直接对它们进行再次编辑。`,
          `点击左上角的“取消”按钮可以退出本次编辑。`,
          `点击右上角的“删除”按钮可以删除这条喝水记录。`,
          `点击顶部标题位置的“时间”按钮可以修改这条喝水记录的记录时间。`,
          `点击底部数字键盘的“√”按钮可以保存本次编辑，并退出喝水页面。`
        ],
        imageNum: 6
      },
      {
        headline: '4.饮品种类',
        subtitle: '4.1 饮品排序',
        content: [
          `在设置页面的“饮品设置”页面，您可以对饮品进行管理。`,
          `长按饮品右侧的移动按钮可以进入排序模式，拖拽饮品插入到想要的位置，松开手指即可。`,
          `点击饮品左侧的“减号”按钮，可以将您不会用到的饮品隐藏，它们将不再出现在喝水记录页面。`,
          `点击饮品左侧的“加号”按钮，可以将被您隐藏的饮品取出，它们将重新再次出现在喝水记录页面。`
        ],
        imageNum: 7
      },
      {
        subtitle: '4.2 喝水系数',
        content: [
          `各种饮品的含水量都不一样，所以摄入的水分也不一样。当您新增一条喝水记录时，我们会先计算该饮品的含
          水量，再归入到当天总摄入量。具体的计算规则您可以在设置页面的“喝水系数”中进行查看。`
        ],
        imageNum: 8
      },
      {
        subtitle: '4.3 创建自定义饮品',
        content: [
          `在设置页面的“饮品设置”页面，您可以创建自定义饮品。点击屏幕底部的“创建自定义饮品”，您可以从多方面
          定制您的新饮品。`,
          `是否酒类：新饮品是否含有酒精。`,
          `喝水系数：每100ml新饮品包含多少ml水分（如果您无法计算出详细的数值，可以参考“喝水系数”页面提供的
          其他饮品的数值，设置一个大概的值。）`,
          `咖啡因系数：新饮品是否含有咖啡因，每100ml新饮品包含多少mg咖啡因。`,
          `喝水目标增加：人体摄入酒精后需要补充更多的水分，您可以参考“喝水系数”页面提供的其他酒类饮品的数
          值，根据酒精含量的浓度，选择一个合适的倍率。`
        ],
        imageNum: 9
      },
      {
        headline: '5.删除记录',
        subtitle: '5.1 在喝水页面删除',
        content: [
          `点击右上角的“删除”按钮可以删除这条喝水记录。`
        ]
      },
      {
        subtitle: '5.2 在其它页面删除',
        content: [
          `左划需要删除的记录，右侧将出现删除按钮，点击删除按钮即可快速删除，点击其它区域可以隐藏该按钮。`
        ],
        imageNum: 10
      },
      {
        headline: '6.遗忘补录',
        content: [
          `昨天喝的水忘记记录了不用怕，我们提供了历史补录功能。`,
          `在历史页面找到这一天，点击“历史记录”标题右侧的“添加”按钮，即可补录。点击屏幕顶部的时间按钮可以修
          改这条记录的时间。`
        ],
        imageNum: 11
      },
      {
        headline: '7.定制喝水目标',
        content: [
          `每个人对水分的需求量都不相同，「喝水时间」将通过性别、体重、运动量来计算你每日所需的喝水量。
          在“个人资料”页面，可以修改您的个人数据。我们会根据您提供的新数据计算出新的每日建议喝水量
          您可以根据我们提供的参考值修改喝水目标，也可以根据个人需求进行调整。`,
          `「喝水时间」不是一款医疗应用，每日建议喝水量仅供参考。如果您想进行医疗目的或获取更加专业的水分需
          求，请咨询您的医生。`
        ],
        imageNum: 12
      },
      {
        headline: '8.Apple「健康」',
        content: [
          `开启连接 Apple「健康」 的权限，可以将您的喝水记录数据都传输到 Apple「健康」 内。`,
          `打开和关闭的方法：前往 Apple「健康」>来源>「喝水时间」`
        ]
      },
      {
        headline: '9.iCloud 同步',
        content: [
          `开启 iCloud 同步可以让您登录于同一 Apple ID 下的多个设备上的喝水记录保持同步，使用该功能需要连接
          网络（若无法使用网络，同步将在下一次成功连接到网络时进行）。`,
          `Apple ID 是「喝水时间」唯一使用的账号，所有功能和数据都将跟随您的 Apple ID，您无需在 App 内进行
          登陆或登出。`
        ]
      },
      {
        headline: '10.铃声',
        subtitle: '10.1 选择铃声',
        content: [
          `我们提供了若干种有趣的铃声，通过屏幕底部的标签栏进入设置页面，点击“铃声”，打开设备的声音开关并调
          节音乐声音大小，即可试听并挑选您喜欢的铃声。`
        ],
        imageNum: 13
      },
      {
        subtitle: '10.2 没有声音',
        content: [
          `您的手设备可能开启了静音模式。铃声的开关、大小、震动由您的设备进行控制，如果您的手机处于静音模式
          铃声将不会正常播放。请确保您打开了手机左侧控制铃声的物理开关，并且在系统设置的“声音与触感”中
          “震动”和“铃声”均为开启状态。`
        ],
        imageNum: 14
      },
      {
        headline: '11.推送语',
        subtitle: '11.1 系统模式',
        content: [
          `「喝水时间」自带一套非常丰富的推送语系统，在系统模式下， 「喝水时间」会自动根据当时的时间来推送合
          适情景的推送语。`
        ]
      },
      {
        subtitle: '11.2 自定义模式',
        content: [
          `在自定义模式下，您可以完全定制一套专属的推送语系统。`,
          `时段：您可以设置多个时段，每个不同地时段可以设定不同的推送语，比如您可以设置一个早上7点到9点的时
          段，并添加一些早安问候的推送语；您也可以设置一个晚上9点到11点的时段，并添加一些晚安问候的推送语。`,
          `推送语：一个时段可以设置一条或多条推送语，如果一个时段内有多条推送语，则每次推送都会在它们中随
          机选取。`
        ],
        imageNum: 15
      },
      {
        headline: '12.购买与恢复',
        content: [
          `如果您遇到付费后广告依然存在的情况，或是重新安装「喝水时间」后出现了广告，请点击设置页面的
          “恢复购买”按钮，重新同步付费信息。`,
          `如果您多次点击了付费页面的“升级”按钮，请放心，同一个 Apple ID 下只能进行一次购买，不会产生多次
          收费。`
        ]
      },
      {
        headline: '13.自定义组件',
        subtitle: '13.1 杯子',
        content: [
          `「喝水时间」为您准备了4个自定义的杯子，您可以从屏幕底部的标签栏进入“设置”页面，点击“自定义组件”，
          自由设置它们的名称、饮品和容量。`,
          `这些杯子将会出现在：`,
          `Apple Watch 中「喝水时间」的首页`,
          `iPhone 中「喝水时间」的喝水页面`,
          `iPhone 和 iPad 的 Widget 小组件`,
          `iPhone 的 3D Touch`,
          `iPhone 和 Apple Watch 的消息通知（展开后出现）`
        ],
        imageNum: 16
      },
      {
        subtitle: '13.2 3D Touch',
        content: [
          `您可以在设备的主界面通过 3D Touch 来使用「喝水时间」的快捷操作：在您的设备主界面找到「喝水时间」
          的图标，长按图标，弹出操作列表后松开。`
        ],
        imageNum: 17
      },
      {
        subtitle: '13.3 Widget 小组件',
        content: [
          `您可以将「喝水时间」添加到系统的 Widget 小组件中，不需要打开「喝水时间」也能快速添加喝水记录。`,
          `添加方法：滑动手机系统主界面到最左侧，点击底部的“编辑”按钮，找到「喝水时间」，点击左侧对应的
          “添加”按钮，然后点击右上角的“完成”。`
        ],
        imageNum: 18
      },
      {
        subtitle: '13.4 展开通知',
        content: [
          `当「喝水时间」向您推送喝水提醒时，您可以展开操作窗口快速记录：在收到消息推送时，下拉消息推送，
          即可展开操作窗口。`
        ],
        imageNum: 19
      },
      {
        headline: '14.其他问题',
        content: [
          `如果您有其它的使用问题，或意见反馈，欢迎您通过“帮助&反馈”中的新浪微博/邮箱/Facebook与我们进行
          联系，我们将会尽快回复您的问题。`
        ]
      },
    ]
  },


  tc: {
    title: '「喝水時間」 v4.0 使用幫助',
    describe: [
      '歡迎使用「喝水時間」。',
      `這是壹款用最簡單的方法跟蹤您的水平衡的工具，幫您養成按時喝水的健康習慣。`,
      `它可以：計算您每日所需喝水量、記錄喝水量、定時提醒喝水、分析歷史喝水數據……`,
      '使用「喝水时间」，保持水分充足！'
    ],
    structureTite: '目錄',
    structure: [
      '1.喝水提醒',
      '2.創建記錄',
      '3.編輯記錄',
      '4.飲品種類',
      '5.刪除記錄',
      '6.遺忘補錄',
      '7.定制喝水目標',
      '8.Apple 「健康」',
      '9.iCould 同步',
      '10.鈴聲',
      '11.推送語',
      '12.購買與恢復',
      '13.自定義組件',
      '14.其他問題'
    ],
    contents: [
      {
        headline: '1.喝水提醒',
        subtitle: '1.1 通知權限',
        content: [
          `「喝水時間」需要獲取系統通知權限，才能向您推送喝水提醒。`,
          `打開方式：設置頁面>通知>允許提醒>跳轉至系統設置>通知>允許通知`
        ],
        imageNum: 0
      },
      {
        subtitle: '1.2 定時提醒模式',
        content: [
          `定時提醒可以選擇您想要提醒的時間點進行提醒。`,
          `添加時間點：點擊頁面底部的“添加新的時間點”，選擇需要提醒的時間點後，點擊“完成”。`,
          `刪除時間點：左劃需要刪除的時間點，右側將出現刪除按鈕，點擊刪除按鈕即可快速刪除，點擊其它區域可以
          隱藏該按鈕。`
        ],
        imageNum: 1
      },
      {
        subtitle: '1.3 間隔提醒模式',
        content: [
          `間隔提醒可以在您設定的非休息時間內，按照您設定的時間間隔進行提醒。`,
          `午休模式：開啟午休模式後，午休時間將作為休息時間，午休期間不會進行提醒。`,
          `自定義間隔時長：點擊“自定義”，選擇需要提醒的時間間隔後，點擊“完成”。`
        ],
        imageNum: 2
      },
      {
        subtitle: '1.4 智能提醒',
        content: [
          `開啟智能提醒模式後，在原本預計下次提醒前的10分鐘內，您記錄了壹次喝水，則這壹次的喝水提醒會
          取消，從而減少無意義的提醒。`,
          `但如果這壹次喝水記錄是通過 Apple Watch 進行記錄的，則智能提醒會不生效，這是壹個Bug，我們正
          在想辦法解決。`
        ]
      },
      {
        subtitle: '1.5 周末模式',
        content: [
          `開啟周末模式後，在周六、周日中午的12點前，「喝水時間」將不會為您推送喝水提醒。但如果在周六、周日
          中午的12點前，您已經起床並記錄了壹次喝水記錄後，「喝水時間」將會恢復當天正常的喝水提醒。`
        ]
      },
      {
        headline: '2.創建記錄',
        subtitle: '2.1 創建',
        content: [
          `點擊首頁底部的“喝水”按鈕，可以創建今天的喝水記錄。`,
          `點擊歷史頁面底部的“添加”按鈕，可以創建歷史當天的喝水記錄。`
        ],
        imageNum: 3
      },
      {
        subtitle: '2.2 選擇飲品',
        content: [
          `您可以點擊上方已經設置好的杯子來快速進行記錄，也可以點擊下方各式各樣的飲品圖標來選擇本次需要記錄
          的飲品，飲品種類會不定時更新（您可以通過“幫助&反饋”聯系我們，告訴我們您還想要添加哪些飲品種類）。`
        ]
      },
      {
        subtitle: '2.3 輸入數值',
        content: [
          `選擇飲品後，將會彈出數字鍵盤，您需要通過數字鍵盤輸入本次攝入的水量。`,
          `您可以通過使用標有刻度值的杯子/瓶子，或是觀察飲品外包裝上標註的凈含量來估算您本次攝入的水量數值。`
        ],
        imageNum: 4
      },
      {
        subtitle: '2.4 修改時間',
        content: [
          `點擊頂部標題位置的“時間”按鈕可以修改這條喝水記錄的記錄時間。`
        ],
        imageNum: 5
      },
      {
        subtitle: '2.5 保存',
        content: [
          `點擊底部數字鍵盤的“√”按鈕可以保存本次編輯，並退出喝水頁面。`
        ]
      },
      {
        headline: '3.編輯記錄',
        content: [
          `點擊「喝水時間」內的任意壹條喝水記錄，您都可以直接對它們進行再次編輯。`,
          `點擊左上角的“取消”按鈕可以退出本次編輯。`,
          `點擊右上角的“刪除”按鈕可以刪除這條喝水記錄。`,
          `點擊頂部標題位置的“時間”按鈕可以修改這條喝水記錄的記錄時間。`,
          `點擊底部數字鍵盤的“√”按鈕可以保存本次編輯，並退出喝水頁面。`
        ],
        imageNum: 6
      },
      {
        headline: '4.飲品種類',
        subtitle: '4.1 飲品排序',
        content: [
          `在設置頁面的“飲品設置”頁面，您可以對飲品進行管理。`,
          `長按飲品右側的移動按鈕可以進入排序模式，拖拽飲品插入到想要的位置，松開手指即可。`,
          `點擊飲品左側的“減號”按鈕，可以將您不會用到的飲品隱藏，它們將不再出現在喝水記錄頁面。`,
          `點擊飲品左側的“加號”按鈕，可以將被您隱藏的飲品取出，它們將重新再次出現在喝水記錄頁面。`
        ],
        imageNum: 7
      },
      {
        subtitle: '4.2 喝水系數',
        content: [
          `各種飲品的含水量都不壹樣，所以攝入的水分也不壹樣。當您新增壹條喝水記錄時，我們會先計算該飲品的含
          水量，再歸入到當天總攝入量。具體的計算規則您可以在設置頁面的“喝水系數”中進行查看。`
        ],
        imageNum: 8
      },
      {
        subtitle: '4.3 創建自定義飲品',
        content: [
          `在設置頁面的“飲品設置”頁面，您可以創建自定義飲品。點擊屏幕底部的“創建自定義飲品”，您可以從多
          方面定制您的新飲品。`,
          `是否酒類：新飲品是否含有酒精。`,
          `喝水系數：每100ml新飲品包含多少ml水分（如果您無法計算出詳細的數值，可以參考“喝水系數”頁面提供的其
          他飲品的數值，設置壹個大概的值。）`,
          `咖啡因系數：新飲品是否含有咖啡因，每100ml新飲品包含多少mg咖啡因。`,
          `喝水目標增加：人體攝入酒精後需要補充更多的水分，您可以參考“喝水系數”頁面提供的其他酒類飲品的數值，
          根據酒精含量的濃度，選擇壹個合適的倍率。`
        ],
        imageNum: 9
      },
      {
        headline: '5.刪除記錄',
        subtitle: '5.1 在喝水頁面刪除',
        content: [
          `點擊右上角的“刪除”按鈕可以刪除這條喝水記錄。`
        ]
      },
      {
        subtitle: '5.2 在其它頁面刪除',
        content: [
          `左劃需要刪除的記錄，右側將出現刪除按鈕，點擊刪除按鈕即可快速刪除，點擊其它區域可以隱藏該按鈕。`
        ],
        imageNum: 10
      },
      {
        headline: '6.遺忘補錄',
        content: [
          `昨天喝的水忘記記錄了不用怕，我們提供了歷史補錄功能。`,
          `在歷史頁面找到這壹天，點擊“歷史記錄”標題右側的“添加”按鈕，即可補錄。點擊屏幕頂部的時間按鈕可以
          修改這條記錄的時間。`
        ],
        imageNum: 11
      },
      {
        headline: '7.定制喝水目標',
        content: [
          `每個人對水分的需求量都不相同，「喝水時間」將通過性別、體重、運動量來計算妳每日所需的喝水量。`,
          `在“個人資料”頁面，可以修改您的個人數據。我們會根據您提供的新數據計算出新的每日建議喝水量，您可以
          根據我們提供的參考值修改喝水目標，也可以根據個人需求進行調整。`,
          `「喝水時間」不是壹款醫療應用，每日建議喝水量僅供參考。如果您想進行醫療目的或獲取更加專業的水分需
          求，請咨詢您的醫生。`
        ],
        imageNum: 12
      },
      {
        headline: '8.Apple「健康」',
        content: [
          `開啟連接 Apple「健康」 的權限，可以將您的喝水記錄數據都傳輸到 Apple「健康」 內。`,
          `打開和關閉的方法：前往 Apple「健康」>來源>「喝水時間」`
        ]
      },
      {
        headline: '9.iCloud 同步',
        content: [
          `開啟 iCloud 同步可以讓您登錄於同壹 Apple ID 下的多個設備上的喝水記錄保持同步，使用該功能需要連接
          網絡（若無法使用網絡，同步將在下壹次成功連接到網絡時進行）。`,
          `Apple ID 是「喝水時間」唯壹使用的賬號，所有功能和數據都將跟隨您的 Apple ID，您無需在 App 
          內進行登陸或登出。`
        ]
      },
      {
        headline: '10.鈴聲',
        subtitle: '10.1 選擇鈴聲',
        content: [
          `我們提供了若幹種有趣的鈴聲，通過屏幕底部的標簽欄進入設置頁面，點擊“鈴聲”，打開設備的聲音開關並
          調節音樂聲音大小，即可試聽並挑選您喜歡的鈴聲。`
        ],
        imageNum: 13
      },
      {
        subtitle: '10.2 沒有聲音',
        content: [
          `您的手設備可能開啟了靜音模式。鈴聲的開關、大小、震動由您的設備進行控制，如果您的手機處於靜音模式，
          鈴聲將不會正常播放。請確保您打開了手機左側控制鈴聲的物理開關，並且在系統設置的“聲音與觸感”中，
          “震動”和“鈴聲”均為開啟狀態。`
        ],
        imageNum: 14
      },
      {
        headline: '11.推送語',
        subtitle: '11.1 系統模式',
        content: [
          `「喝水時間」自帶壹套非常豐富的推送語系統，在系統模式下， 「喝水時間」會自動根據當時的時間來推送
          合適情景的推送語。`
        ]
      },
      {
        subtitle: '11.2 自定義模式',
        content: [
          `在自定義模式下，您可以完全定制壹套專屬的推送語系統。`,
          `時段：您可以設置多個時段，每個不同地時段可以設定不同的推送語，比如您可以設置壹個早上7點到9點的
          時段，並添加壹些早安問候的推送語；您也可以設置壹個晚上9點到11點的時段，並添加壹些晚安問候的推
          送語。`,
          `推送語：壹個時段可以設置壹條或多條推送語，如果壹個時段內有多條推送語，則每次推送都會在它們中隨
          機選取。`
        ],
        imageNum: 15
      },
      {
        headline: '12.購買與恢復',
        content: [
          `如果您遇到付費後廣告依然存在的情況，或是重新安裝「喝水時間」後出現了廣告，請點擊設置頁面的
          “恢復購買”按鈕，重新同步付費信息。`,
          `如果您多次點擊了付費頁面的“升級”按鈕，請放心，同壹個 Apple ID 下只能進行壹次購買，不會產生多次
          收費。`
        ]
      },
      {
        headline: '13.自定義組件',
        subtitle: '13.1 杯子',
        content: [
          `「喝水時間」為您準備了4個自定義的杯子，您可以從屏幕底部的標簽欄進入“設置”頁面，點擊
          “自定義組件”，自由設置它們的名稱、飲品和容量。`,
          `這些杯子將會出現在：`,
          `Apple Watch 中「喝水時間」的首頁`,
          `iPhone 中「喝水時間」的喝水頁面`,
          `iPhone 和 iPad 的 Widget 小組件`,
          `iPhone 的 3D Touch`,
          `iPhone 和 Apple Watch 的消息通知（展開後出現）`
        ],
        imageNum: 16
      },
      {
        subtitle: '13.2 3D Touch',
        content: [
          `您可以在設備的主界面通過 3D Touch 來使用「喝水時間」的快捷操作：在您的設備主界面找到
          「喝水時間」的圖標，長按圖標，彈出操作列表後松開。`
        ],
        imageNum: 17
      },
      {
        subtitle: '13.3 Widget 小組件',
        content: [
          `您可以將「喝水時間」添加到系統的 Widget 小組件中，不需要打開「喝水時間」也能快速添加喝水記錄。`,
          `添加方法：滑動手機系統主界面到最左側，點擊底部的“編輯”按鈕，找到「喝水時間」，點擊左側對應的
          “添加”按鈕，然後點擊右上角的“完成”。`
        ],
        imageNum: 18
      },
      {
        subtitle: '13.4 展開通知',
        content: [
          `當「喝水時間」向您推送喝水提醒時，您可以展開操作窗口快速記錄：在收到消息推送時，下拉消息推送，
          即可展開操作窗口。`
        ],
        imageNum: 19
      },
      {
        headline: '14.其他問題',
        content: [
          `如果您有其它的使用問題，或意見反饋，歡迎您通過“幫助&反饋”中的新浪微博/郵箱/Facebook與我們進
          行聯系，我們將會盡快回復您的問題。`
        ]
      },
    ]
  }
}
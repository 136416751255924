<template>
  <div class="privacy">
    <!-- <Nav @language-change="handleLangChange"></Nav> -->

    <div class="privacy-content">
      <el-row>
        <h1 style="margin: 20px auto">{{helpContent.title}}</h1>
      </el-row>

      <el-row v-for="(item, index) in helpContent.describe" :key="index">{{item}}</el-row>

      <div v-for="(item, index) in helpContent.contents" :key="index">
        <h2 v-if="item.subtitle">{{item.subtitle}}</h2>
        <section v-for="(temp, key) in item.content_li_befor" :key="key">{{temp}}</section>
        <br><li v-for="(temp_li, key) in item.content_li" :key="key">{{temp_li}}</li><br>
        <section v-for="(temp, key) in item.content" :key="key">{{temp}}</section>
      </div>
    </div>
  </div>
</template>

<script>
// import Nav from '@/components/nav'
import helpContents from '@/config/privacy';
export default {
  name: 'water-privacy',

  components: {
    // 'Nav': Nav
  },

  created() {
    this.helpContent = helpContents.en;
  },

  data () {
    return {
      helpContent: '',
      navLanguageImage: {
        en: require('@/assets/en/nav_en.png'),
        tc: require('@/assets/tc/nav_tc.png'),
        cn: require('@/assets/cn/nav_cn_checked.png')
      },
      logoImage: require('@/assets/nav_logo.png')
    }
  },

  methods:{
    // handleLangChange(type) {
    //   this.helpContent = helpContents[type];
    // },
  }
}
</script>

<style>
a {
  color: black;
}
.privacy-content {
  width: 62%;
  margin: 10px auto;
}
</style>
<template>
  <div class="help">
    <div class="help-content">
      <el-row>
        <h1 style="margin: 20px auto">{{helpContent.title}}</h1>
      </el-row>

      <el-row v-for="(item, index) in helpContent.describe" :key="index">{{item}}</el-row>

      <el-row><h4>{{helpContent.structureTite}}</h4></el-row>
      
      <el-row>
        <span>
          <a
            v-for="(item, index) in helpContent.structure"
            :key="index">{{item}}<br>
          </a>
        </span>
      </el-row>

      <div v-for="(item, index) in helpContent.contents" :key="index">
        <h2 v-if="item.headline">{{item.headline}}</h2>
        <h4 v-if="item.subtitle">{{item.subtitle}}</h4>
        <section v-for="(temp, key) in item.content" :key="key">{{temp}}</section>
        <el-image v-if="showImage(item.imageNum)" :src="contentImages[item.imageNum]"></el-image>
      </div>

    </div>
  </div>
</template>

<script>
import helpContents from '@/config/help';

export default {
  name: 'water-help-cn',

  components: {
  },

  created() {
    this.helpContent = helpContents.cn;
  },

  mounted() {

  },

  data () {
    return {
      contentImages: [
        require('@/assets/help/cn/help_page_cn_0.png'),
        require('@/assets/help/cn/help_page_cn_1.png'),
        require('@/assets/help/cn/help_page_cn_2.png'),
        require('@/assets/help/cn/help_page_cn_3.png'),
        require('@/assets/help/cn/help_page_cn_4.png'),
        require('@/assets/help/cn/help_page_cn_5.png'),
        require('@/assets/help/cn/help_page_cn_6.png'),
        require('@/assets/help/cn/help_page_cn_7.png'),
        require('@/assets/help/cn/help_page_cn_8.png'),
        require('@/assets/help/cn/help_page_cn_9.png'),
        require('@/assets/help/cn/help_page_cn_10.png'),
        require('@/assets/help/cn/help_page_cn_11.png'),
        require('@/assets/help/cn/help_page_cn_12.png'),
        require('@/assets/help/cn/help_page_cn_13.png'),
        require('@/assets/help/cn/help_page_cn_14.png'),
        require('@/assets/help/cn/help_page_cn_15.png'),
        require('@/assets/help/cn/help_page_cn_16.png'),
        require('@/assets/help/cn/help_page_cn_17.png'),
        require('@/assets/help/cn/help_page_cn_18.png'),
        require('@/assets/help/cn/help_page_cn_19.png')
      ],
      helpContent: ''
    }
  },

  methods:{
    showImage(num) {
      if ('undefined' === typeof num) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style>
.help-content {
  width: 62%;
  margin: 20px auto;
}

.help-content img {
  margin: 20px 0 0 0;
}
</style>
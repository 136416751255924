<template>
  <div class="main">
    <HomePC v-if="isHomePC" :path="path"></HomePC>
    <HomeMobile v-if="!isHomePC" :path="path"></HomeMobile>

    <div class="record-number">
      <div>@2016-2024 深圳鑫创信息技术有限公司 版权所有. </div>
      <a href="https://beian.miit.gov.cn/">粤ICP备2023095829号</a>
    </div>
  </div>
</template>

<script>
import HomePC from './home/pc'
import HomeMobile from './home/mobile'
export default {
  name: 'water-home',
  components: {
    'HomePC': HomePC,
    'HomeMobile': HomeMobile
  },

  data () {
    return {
      isHomePC: true,
      minWindowWidth: 600,
      path: {
        weibo: 'https://weibo.com/u/7300602267',
        // facebook: 'https://www.facebook.com/MyWaterTracker',
        facebook: "https://www.xiaohongshu.com/user/profile/5c8920d60000000010018d47?appuid=5c8920d60000000010018d47",
        email: 'hustapp@foxmail.com',
        appDownload: 'https://apps.apple.com/app/id1404193468'
      }
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  methods:{
    onResize() {

      const windowWidth = window.innerWidth

      if (windowWidth <= this.minWindowWidth) {
        this.isHomePC = false;
      } else {
        this.isHomePC = true;
      }

    }
  }
}
</script>

<style>
.logo-download {
  position: absolute;
  top: 58.6%;
  left: 5.2%;
  width: 15.8%;
  height: 8.5%;
}

.logo-download:hover {
  cursor: pointer;
}

.logo-help {
  position: absolute;
  top: 87.3%;
  height: 7.3%;
}

.logo-help:hover {
  cursor: pointer;
}

.logo-icon {
  position: absolute;
  width: 10.4%;
  /* height: 3.3%; */
}

.logo-weibo {
  top: 34.7%;
  left: 52%;
}

.logo-facebook {
  top: 46.1%;
  left: 52%;
}

.logo-email {
  top: 57.5%;
  left: 52%;
}

.logo-terms {
  top: 34.7%;
  left: 72.2%;
}

.logo-privacy {
  top: 46.1%;
  left: 72.2%;
}

.logo-icon:hover {
  cursor: pointer;
}

.record-number {
  text-align: center;
  position: relative;
  bottom: 2.5vw;
  font-size: calc(100vw * 16 / 1920);
  color: #535353;
  font-family: SF Pro-Regular, SF Pro;
}

.record-number a {
  color: #535353;
}

.record-number div {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .record-number {
    font-size: calc(0.2rem + 1.5vw);
  }
}
</style>
